<template>
  <ion-menu
    @ionDidOpen="showContent = true"
    @ionWillClose="showContent = false"
    menu-id="side"
    class="menu side-menu"
    content-id="main-content"
    :swipeGesture="false"
  >
    <ion-content v-if="showContent">
      <div class="content position-relative">
        <div class="safe-top position-relative" />
        <UserArea class="user-area-c" />
        <!-- <div class="buttons mt-3 mx-3 d-flex">
          <router-link class="flex-grow-1" to="/my-creations?tab=bookmarks">
            <ion-button class="other-btn w-100">
              <inline-svg class="mr-1" src="/icons/bookmark.svg" />
              My Bookmarks
            </ion-button>
          </router-link>
          <router-link class="flex-grow-1" to="/my-creations?tab=reactions">
            <ion-button class="other-btn w-100">
              <inline-svg class="mr-1" src="/icons/thumbs-up-like.svg" />
              My Reactions
            </ion-button>
          </router-link>
        </div> -->
        <div class="main-buttons mt-3 d-flex px-3">
          <router-link :to="{ name: 'profile', params: { username: get(user, 'username') } }">
            <ion-button class="one-button w-100">
              <div class="d-flex align-items-center justify-content-center w-100 flex-column">
                <div class="action-area d-flex align-items-center flex-column justify-content-center">
                  <inline-svg src="/icons/person-outline.svg" class="person-svg" /> My Profile
                </div>
              </div>
            </ion-button>
          </router-link>

          <ion-button class="one-button w-100" @click="toggleMyCreations">
            <div class="d-flex align-items-center justify-content-center w-100 flex-column">
              <div class="action-area d-flex align-items-center flex-column justify-content-center">
                <inline-svg src="/icons/creations.svg" /> My Creations
              </div>
            </div>
          </ion-button>

          <ion-button class="one-button w-100" @click="goTo('social-space', $event)">
            <div
              class="d-flex align-items-center justify-content-center w-100 flex-column"
              :class="{ disabled: !userHasCharacters }"
            >
              <div class="action-area d-flex align-items-center flex-column justify-content-center">
                <inline-svg src="/icons/social-spaces.svg" /> Social Spaces
              </div>
              <div v-if="hasUnreadWorldMsgs" class="has-new">{{ hasUnreadWorldMsgs }}</div>
            </div>
          </ion-button>

          <ion-button class="one-button w-100" @click="goTo('marketplace', $event)">
            <div
              class="d-flex align-items-center justify-content-center w-100 flex-column"
              :class="{ disabled: !userHasCharacters }"
            >
              <div class="action-area d-flex align-items-center flex-column justify-content-center">
                <inline-svg src="/icons/marketplace.svg" /> Marketplace
              </div>
            </div>
          </ion-button>
        </div>

        <div class="challenge mt-3" v-if="!userHasCharacters && challengeEndTime">
          <div class="text-center">
            <span class="character-text"> CREATE A CHARACTER </span>
            <span class="mx-1 challenge-text"> CHALLENGE </span>
          </div>
          <OnboardingCharacterCreationChallengeCard
            :displayCreationButton="true"
            :display-white-bg="true"
            :challengeEndTime="challengeEndTime"
          />
        </div>
        <div class="creations p-3 mt-3" v-else>
          <Creations
            :isVisible="true"
            :ringWidth="75"
            :folders="folders"
            :ringTopAlignment="-49"
            :ringLeftAlignment="0"
          />
        </div>
        <div class="px-3">
          <router-link to="/shop" class="position-relative">
            <div class="freebie" v-if="get(usersFreebie, 'status') === 'closed'">FREE</div>
            <img loading="lazy" class="ch-shop mt-3" src="/ch-shop.svg" />
          </router-link>
          <div style="position: relative" v-if="!isUserSubscribed">
            <div class="d-flex align-items-center mx-auto justify-content-center" v-if="discountEndTime">
              <ion-badge class="timer-badge">
                <div>Welcome Discount!</div>

                <div><i class="ti-timer mx-1" style="font-size: 12px" /></div>
                <vue-countdown v-slot="{ days, hours, minutes, seconds }" :time="discountEndTime">
                  <span class="countdown">
                    <span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
                    ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span>
                  </span>
                </vue-countdown></ion-badge
              >
            </div>
            <img
              v-if="featureFlags.pro"
              loading="lazy"
              class="ch-shop"
              src="/images/ssub2.svg"
              @click="openSubscribeModal($event, 'sidebar-mobile')"
            />
          </div>
        </div>

        <div class="image-storage mt-2">
          <ImageStorageSpace
            :is-side-menu="true"
            v-if="featureFlags.storage && userStoragePercentage >= 90"
            upgrade-tag-bg-color="#fff"
            :show-title="true"
          />
        </div>
        <FooterButtons :isVisible="true" />
      </div>
    </ion-content>
    <ion-content v-else>
      <div class="w-100 p-3">
        <ion-skeleton-text v-for="(_, index) of [1, 2, 3, 4]" :key="index" class="mb-3" animated />
      </div>
    </ion-content>
  </ion-menu>
</template>

<script lang="ts" setup>
import FooterButtons from './FooterButtons.vue';
import Creations from './Creations.vue';
import ImageStorageSpace from './ImageStorageSpace.vue';
import UserArea from './UserArea.vue';
import store from '@/shared/store';
import { authStore } from '@/shared/pinia-store/auth';
import { featureFlags } from '@/shared/config/feature-flags';
import { openSubscribeModal } from '@/shared/utils/modals';
import { uiStore } from '../pinia-store/ui';
import { getProfileFolders } from '@/shared/actions/collections';
import { Collection, Paging } from '@/shared/types/static-types';
import OnboardingCharacterCreationChallengeCard from './OnboardingCharacterCreationChallengeCard.vue';
import { popovers } from '@/shared/native';
import CharacterCreationPopover from '@/shared/components/popovers/character-creation-popover.vue';
const { userStoragePercentage, usersFreebie, user, userHasCharacters } = authStore();
const { toggleMyCreations } = uiStore();
const discountEndTime: any = ref(null);
const challengeEndTime: any = ref(null);
const { challengeEndsAt } = useOnboardingChallengeTimer();
const showContent = ref(false);
const foldersPage = ref(1);
const folders = ref<Collection[]>([]);
const foldersPaging = ref<Paging>();
const { titleColor } = useCharPageColors();

const hasUnreadWorldMsgs = computed(() => {
  return store.getters['WorldsModule/hasUnreadWorldMsgs'];
});

const isUserSubscribed = computed(() => {
  const { subscriptionLevel } = authStore();
  return subscriptionLevel.value > 0;
});
const goTo = async (routeName: any, ev: CustomEvent) => {
  const router = useRouter();
  if (!userHasCharacters.value) {
    await popovers.open(ev, CharacterCreationPopover);
    return;
  }
  router.push({ name: routeName });
};

onMounted(() => {
  const { discountEndsAt } = useWelcomeDiscountTime();
  discountEndTime.value = discountEndsAt();
  challengeEndTime.value = challengeEndsAt();
  setTimeout(() => {
    discountEndTime.value = 0;
  }, discountEndTime.value);

  setTimeout(() => {
    challengeEndTime.value = 0;
  }, challengeEndTime.value);
});
const loadFolders = async () => {
  if (!user.value?.username) return;

  const { results, ...rest } = await getProfileFolders(user.value?.username, foldersPage.value);

  foldersPaging.value = rest;
  folders.value = [...folders.value, ...results];
};
onMounted(async () => {
  await loadFolders();
});
</script>

<style lang="sass" scoped>
.character-text
  font: bolder 20px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #431C89
.challenge-text
  font: bolder 35px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #fff
.challenge
  background: linear-gradient(180deg, rgba(210, 228, 255, 0) 0%, #D2E4FF 100%), #D7B9FF
  padding: 16px
.safe-top
  height: var(--safe-ios-margin)
  z-index: 2
  background: #FFF
.content
  padding-bottom: calc(var(--safe-ios-margin)/3) !important
.person-svg
  width: 22px
  height: 21px
.freebie
  font-size: 12px
  display: flex
  z-index: 2
  color: white
  background: linear-gradient(90deg, #6ADF32 0%, rgba(56, 226, 83, 0) 100%), #1B856B
  border: 2px solid #214163
  border-radius: 5px
  position: absolute
  top: 12px
  left: -1px
  width: 40px
  justify-content: center
  align-items: center
  text-align: center
.timer-badge
  padding: 6px 8px 6px 8px
  display: flex
  align-items: center
  justify-content: center
  background: linear-gradient(to right, #FF004D, #EE4035)
  font-size: 14px
  border-radius: 7px
  position: absolute
  bottom: -10px
.user-area-c
  ::v-deep
    .user-area
      max-width: unset !important
      min-width: unset !important
      padding: 9px 18px !important

@media(max-width: 500px)
  .menu
    --width: 100vw
.has-new
  height: 16px
  border-radius: 8px
  color: #FFF
  background: rgba(255, 56, 100, 1)
  display: flex
  align-items: center
  justify-content: center
  font-weight: bold
  font-size: 10px
  padding: 0 5px
  position: absolute
  right: -20px
  top: -10px
ion-button
  margin: 0
a
  display: block
  overflow: visible
.creations
  background: rgba(234, 236, 246, 1)
.main-buttons
  grid-gap: 4px
  flex-wrap: wrap
.one-button
  width: 56px !important
  height: 56px
  max-width: 56px
  border-radius: 6px
  background: #7050B7 !important
  --background: #7050B7
  color: white
  align-items: center
  justify-content: center
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  --overflow: visible
  overflow: visible
  ::v-deep
    svg
      margin-bottom: 5px
      path
        stroke: white
      rect
        stroke: white
  .action-area
    font-size: 8px
    font-weight: bold
.soon
  font-size: 9px
  color: #fff
  border-radius: 10px
  padding: 2px 4px
  position: absolute
  background: rgba(255, 56, 100, 1)
  z-index: 1
  right: -5px
.other
  .title
    color: rgba(84, 115, 149, 1)
    font-weight: bold
.dark .other .title
  color: #fff !important
.buttons
  grid-gap: 4px
.other-btn
  border: 1px solid rgba(84, 115, 149, 1)
  border-radius: 6px
  box-shadow: 0px 0px 4px 0px rgba(33, 65, 99, 0.4)
  --background: transparent
  color: rgba(84, 115, 149, 1)
  height: 24px
  font-size: 10px
  position: relative

.ch-shop
  height: auto
  width: 100%
  &:hover
    opacity: 0.7
    cursor: pointer
</style>
